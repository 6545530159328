
import React, { Component } from "react"

import Helmet from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Slider from "react-slick"

import Culture_phone from "../images/culture_phone_img.png"
import Culture_slide from "../images/cb/slider1.jpg"
import Culture_slide1 from "../images/cb/cfg1.png"
import Culture_slide2 from "../images/cb/cfg2.png"
import Culture_slide3 from "../images/cb/cfg3.png"
import Culture_slide4 from "../images/cb/cfg4.png"
import Culture_slide5 from "../images/cb/cfg5.png"
import Culture_slide6 from "../images/cb/cfg6.png"
import Culture_slide7 from "../images/cb/cfg7.png"
import Arrow_down from "../images/arrow_down.png"
import Small_block from "../images/smallBlock.svg"

import Chatur_carlos from "../images/chatur_carlos.png"
import Magento_icon from "../images/m1tom2-bk.png"
import Shopify_icon from "../images/m1toshopify-bk.png"

import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'


const culture__slider = {
  dots: true,
  infinite: true,
  speed: 500,
  fade: true,
  autoplay: true,
  cssEase: 'linear',
  accessibility: true,
};


export default class Container extends Component  {
  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }

  render() {

  return (

  <Layout>
    <Helmet>
        <title>
        CueForGood: eCommerce Agency for B Corps, Sustainable & Purpose Driven Brands</title>
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=5.0, minimum-scale=1.0" />
          <meta property="fb:admins" content="1046558399" />
          <link rel="canonical" href={this.state.url} />
          <meta name="description" content="CueForGood is your eCommerce Agency from Chandigarh, India. We leverage eCommerce for a global audience and love working with Ethical, Earth-Friendly & Purpose Driven Brands. " />
          <meta property="og:site_name" content="CueForGood" />
          <meta property="fb:app_id" content="289086684439915" />
          <meta property="og:url" content={this.state.url} />
          <meta property="og:title" content="CueForGood: eCommerce Agency for B Corps, Sustainable & Purpose Driven Brands " />
          <meta property="og:description" content="CueForGood is your eCommerce Agency from Chandigarh, India. We leverage eCommerce for a global audience and love working with Ethical, Earth-Friendly & Purpose Driven Brands. " />
          <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content="CueForGood is your eCommerce Agency from Chandigarh, India. We leverage eCommerce for a global audience and love working with Ethical, Earth-Friendly & Purpose Driven Brands. " />
          <meta name="twitter:title" content="CueForGood: eCommerce Agency for B Corps, Sustainable & Purpose Driven Brands " />
          <meta name="twitter:site" content="@CueForGood" />
          <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg" />

    </Helmet>
    <section className="home-slider__row">
        
      <div className="container">
          <div className="line1 line"></div>
          <div className="line2 line"></div>
          <div className="homepage-title">
              <h4 className="homeslider-header">Leveraging eCommerce for Purpose</h4>
              <h1>We are an eCommerce agency for B Corps, sustainable, planet-friendly and purpose driven brands.</h1>
              <span className="home-backImage"></span>
              <span className="home-title-text">20 Years Exp
                <span>
                <img src={Small_block} alt="Block small" />
                </span>
                 ~40 Experts</span>
        </div>
        {/* <ul>
          <li>
            <Link to="/magento-1-to-magento-2-migration-service/">
            <img src={Magento_icon} alt="Magento1 to Magento2 Migration" />
            <p>Magento 1 to Magento 2</p>
            </Link>
          </li>
          <li>
            <div className="talkdesign">
              <Link to="/contact/">
               <span>Let's</span>
              <br />
              <span>Talk eCommerce</span>
              </Link>
            </div>
            <a href="#homewhat" className="movedown">
              <img src={Arrow_down} alt="Arrow down" />
            </a>
          </li>
          <li>
            <Link to="/magento-1-to-shopify-migration-service/">
            <img src={Shopify_icon} alt="Magento1 to Shopify Migration" />
            <p>Magento 1 to Shopify</p>
            </Link>
          </li>
        </ul> */}
        

      </div>
    </section>
    <section className="home-what__we_do" id="homewhat">
      <div className="container">
        <div className="home-what__we_left pd_right_40">
          <h2 className="heading_main">What we do</h2>
        
          <p>
            We fuel the digital initiatives of socially-responsible companies and purpose-driven organizations.
            <span><strong>#madebycueforgood</strong></span>
          </p>
        </div>
        <div className="home-what__we_right pd_left_40">
          <p className="p-w-s-space">
            <span>
            Rooted in eCommerce development, digital marketing, eCommerce strategy and creative design, we align with brands eager to make a meaningful impact beyond profit.
            </span>
            <span className="no-space">
            Our spirited tribe of about 40 eCommerce specialists calls India and the EU home. We’re fluent in the eCommerce marketing, design & development of Shopify, Magento, WooCommerce, and PHP stores.
            </span>

            <Link to="/services/" id="first-focus-element" className="read_btn focus-visible" role="button" aria-label="read more about the services of CFG">
              Read more..
            </Link>
          </p>
        </div>
      </div>
    </section>
    <section className="home-culture__row">
      <div className="container">
        <div className="culture__left">
          <p>allthatgrows.in</p>
          <img src={Culture_phone} alt="Culture Phone ATG" aria-hidden="true" />
        </div>
        <div className="culture__right pd_left_40">
          <div className="fade slider">
            <Slider {...culture__slider}>
             
              <div>
                  <img src={Culture_slide1} alt="Culture at CueForGood2" />
                <div className="slide_caption">Culture @CueForGood</div>
              </div>
                <div>
                  <img src={Culture_slide2} alt="Culture at CueForGood2" />
                  <div className="slide_caption">Culture @CueForGood</div>
                </div>
          
              <div>
                  <img src={Culture_slide3} alt="Culture at CueForGood4" />
                <div className="slide_caption">Culture @CueForGood</div>
              </div>
                <div>
                  <img src={Culture_slide4} alt="Culture at CueForGood5" />
                  <div className="slide_caption">Culture @CueForGood</div>
                </div>
                <div>
                  <img src={Culture_slide5} alt="Culture at CueForGood6" />
                  <div className="slide_caption">Culture @CueForGood</div>
                </div>
                <div>
                  <img src={Culture_slide6} alt="Culture at CueForGood7" />
                  <div className="slide_caption">Culture @CueForGood</div>
                </div>
                <div>
                  <img src={Culture_slide7} alt="Culture at CueForGood7" />
                  <div className="slide_caption">Culture @CueForGood</div>
                </div>
                
            </Slider>
          </div>
        </div>
      </div>
    </section>
    <section className="home-weare__row">
      <div className="container">
        <div className="weare__rt pd_left_40">
          <h2 className="heading_main">Who we are</h2>
          <p>
            A team of highly adept professionals, operating in global time zones, we strive to provide stellar E-commerce and Digital Marketing Services that are focused on an ROI-driven approach. 
            <Link to="/about/" className="read_btn focus-visible" role="button" aria-label="read more about the CFG">
              Read more..
            </Link>
          </p>
          <Link to="/career/" className="main_cta focus-visible">
            work with us
          </Link>
        </div>
          <div className="weare__lt pd_right_40">
          <h2>Feed us with</h2>
          
          <img
            style={{ verticalAlign: "top" }}
            src={Chatur_carlos}
            alt="Chatur and Carlos"
            className="img-responsive"
          />
          <p>Chatur & Carlos</p>
           
        </div>
      </div>
    </section>
    <section className="home-blog_case__row" style={{ verticalAlign: "top" }}>
      <div className="container">
        <div className="blog_case__block">
          <div className="blog__col">
            <h3 className="heading_main">case studies</h3>
            <h4>
              ALLTHATGROWS
              <label className="label__g label__m">SHOPIFY</label>
            </h4>
            <p>
              Building brand identity, packaging designs & marketing campaigns for AllThatGrows.
            </p>
              <Link to="/case-study/allthatgrows/" className="read_btn focus-visible" role="button" aria-label="read more about the ATG case study">
              Read more..
              </Link>
          </div>
          <div className="case__col">
            <h3 className="heading_main">case studies</h3>
            <h4>
              PETDOORS
              <label className="label__g label__m">magento</label>
            </h4>

            <p>
             Merging revenue-driven blogs to increase organic sessions by 106.94% for PetDoors.
                 
            </p>
              <Link to="/case-study/merging-blog-pages-seo/" className="read_btn focus-visible" role="button" aria-label="read more about the PD casestudy">
              Read more..
              </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="home-resourse__row">
      <div className="container">
        <h2 className="heading_main">Resources</h2>
        <div className="resourse__lt pd_right_40">
          <p>
            Be sure to check off all the points on this specially-curated Conversion Rate Optimisation (CRO) before investing in your next big eCommerce venture. 
          </p>
          <h3>91 Point CRO Checklist & Infographic</h3>
            <Link to="/holygrail-of-ecommerce-conversion-rate-optimization-91-point-checklist/"
              className="main_cta focus-visible"
              aria-label="Download 91-Point Checklist for eCommerce Conversion Rate Optimization"
              title="Download 91-Point Checklist for eCommerce Conversion Rate Optimization (opens in new tab)">
              Download
            </Link>
        </div>
        <div className="resourse__rt pd_left_40">
          <p>
           Follow our 40-Point Checklist Guide for quick tips & feasible steps to smoothly switch the HTTP version of your website to a more secure version, HTTPS.
          </p>
          <h3>40 Point Checklist for a Successful HTTP to HTTPS Migration </h3>
            <Link to="/40-point-checklist-for-a-successful-http-to-https-migration/"
              className="main_cta focus-visible"
              aria-label="Download 40-Point Checklist for HTTP to HTTPS Migration"
              title="Download 40-Point Checklist for HTTP to HTTPS Migration (opens in new tab)">
              Download
            </Link>
        </div>
      </div>
    </section>
    <Clutch />
  </Layout>
  )
}
}
